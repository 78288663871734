import { logEvent as firebaseLogEvent, getAnalytics } from 'firebase/analytics';

import { getNodeEnv } from '@utils/env';

import type { Analytics } from 'firebase/analytics';

let analytics: Analytics;

const { firebaseAnalyticsEnabled } = getNodeEnv();

if (firebaseAnalyticsEnabled && typeof window !== 'undefined') {
  analytics = getAnalytics();
}

export const logEvent = (event: string, value?: Record<string, unknown>) =>
  analytics && firebaseLogEvent(analytics, event, value);
