/* eslint-disable max-len */
import getConfig from 'next/config';

type GenericDict = { [key: string]: string };

const getFromEnv = (key: string, publicRuntimeConfig: GenericDict): string => {
  return publicRuntimeConfig[key] || process.env[key] || '';
};
/**
 * XXX Next.js FORCES the NODE_ENV to be either "development" or "production" at build time.
 * Because of this, we have a difference between the process.env.NODE_ENV given by Express and the on given by Next
 * In order to avoid this huge issue, we stored the real NODE_ENV in next.runtimeConfig.js:NODE_ENV
 * And this function must be used to get the NODE_ENV instead of process.env.NODE_ENV
 *
 * This function is compatible with Express/Next, and can be used anywhere, on the client and server.
 *
 * @see XXX https://github.com/zeit/next.js/issues/3605#issuecomment-370255754
 */
export const getNodeEnv = () => {
  let publicRuntimeConfig = {} as GenericDict;

  const data = getConfig();

  if (data && data.publicRuntimeConfig) {
    publicRuntimeConfig = { ...data.publicRuntimeConfig };
  }

  const apiHost = getFromEnv('API_HOST', publicRuntimeConfig);
  const apiToken = getFromEnv('NEXT_PUBLIC_API_TOKEN', publicRuntimeConfig);
  const isMockedApi =
    getFromEnv('NEXT_PUBLIC_MOCKED_API', publicRuntimeConfig)?.toUpperCase() === 'ENABLED';
  const isProductionEnv = getFromEnv('NODE_ENV', publicRuntimeConfig) === 'production';

  const nextAuthUrl = getFromEnv('NEXTAUTH_URL', publicRuntimeConfig);
  const nextHost = getFromEnv('NEXT_HOST', publicRuntimeConfig);
  const nextAuthSecret = getFromEnv('NEXT_AUTH_SECRET', publicRuntimeConfig);
  const realNodeEnv = getFromEnv('NODE_ENV', publicRuntimeConfig);
  const sentryDsn = getFromEnv('NEXT_PUBLIC_SENTRY_DSN', publicRuntimeConfig);
  const storybookHost = getFromEnv('STORYBOOK_HOST', publicRuntimeConfig);
  const poolingInterval = getFromEnv('NEXT_PUBLIC_POOLING_INTERVAL', publicRuntimeConfig);
  const firebaseApiKey = getFromEnv('NEXT_FIREBASE_API_KEY', publicRuntimeConfig);
  const firebaseAuthDomain = getFromEnv('NEXT_FIREBASE_AUTH_DOMAIN', publicRuntimeConfig);
  const firebaseProjectId = getFromEnv('NEXT_FIREBASE_PROJECT_ID', publicRuntimeConfig);
  const firebaseAppId = getFromEnv('NEXT_FIREBASE_APP_ID', publicRuntimeConfig);
  const firebaseStorageBucket = getFromEnv('NEXT_FIREBASE_STORAGE_BUCKET', publicRuntimeConfig);
  const firebaseMeasurementId = getFromEnv('NEXT_FIREBASE_MEASUREMENT_ID', publicRuntimeConfig);
  const firebaseAnalyticsEnabled =
    getFromEnv('NEXT_FIREBASE_ANALYTICS_ENABLED', publicRuntimeConfig) === 'true';
  const firebaseMessagingSenderId = getFromEnv(
    'NEXT_FIREBASE_MESSAGING_SENDER_ID',
    publicRuntimeConfig
  );

  return {
    apiHost,
    apiToken,
    isMockedApi,
    isProductionEnv,
    realNodeEnv,
    sentryDsn,
    poolingInterval,
    storybookHost,
    nextAuthUrl,
    nextHost,
    nextAuthSecret,
    firebaseApiKey,
    firebaseAuthDomain,
    firebaseProjectId,
    firebaseAppId,
    firebaseStorageBucket,
    firebaseMessagingSenderId,
    firebaseMeasurementId,
    firebaseAnalyticsEnabled,
  };
};
