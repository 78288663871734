/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */

if (typeof window === 'undefined') {
  const { server } = require('./server');
  server.listen();
} else {
  const { worker } = require('./browser');
  worker.start();
}
