import { getApp, getApps, initializeApp } from 'firebase/app';
import {
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signOut as firebaseSignOut,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { getNodeEnv } from '@utils/env';

import type { FirebaseOptions } from 'firebase/app';
import type { NextOrObserver, User, UserCredential } from 'firebase/auth';

const EMPTY_APP = 0;

const {
  firebaseAnalyticsEnabled,
  firebaseApiKey,
  firebaseAppId,
  firebaseAuthDomain,
  firebaseMessagingSenderId,
  firebaseProjectId,
  firebaseStorageBucket,
} = getNodeEnv();

const firebaseConfig: FirebaseOptions = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  ...(firebaseAnalyticsEnabled && {
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
  }),
};

// CREATE APP
const app = getApps.length > EMPTY_APP ? getApp() : initializeApp(firebaseConfig);

// AUTH
const auth = getAuth(app);

const signIn = (email: string, password: string): Promise<UserCredential> => {
  return signInWithEmailAndPassword(auth, email, password);
};

const onAuthStateChanged = (onStateChanged: NextOrObserver<User>) =>
  firebaseOnAuthStateChanged(auth, onStateChanged);

const signOut = () => firebaseSignOut(auth);

export { app, auth, signIn, signOut, onAuthStateChanged };
