import { rest } from 'msw';

import { getNodeEnv } from '../../utils/env.ts';
import * as fixtures from '../fixtures';

const { nextHost } = getNodeEnv();

// mocked calls used on the application
const handlers = [
  //  findUserBy
  rest.get(`${nextHost}/api/user`, (_, res, ctx) =>
    res(ctx.status(200), ctx.json(fixtures.findUserBy))
  ),
];

export default handlers;
