import axios from 'axios';

import { getNodeEnv } from '@utils/env';

import { User } from '../models';

const { nextHost } = getNodeEnv();

type FindUser = {
  authenticationId: string;
  accessToken: string;
};

export const findUserBy = async (findUser: FindUser): Promise<User> => {
  const { accessToken, authenticationId } = findUser;

  const response = await axios.get(`${nextHost}/api/user`, {
    params: { authenticationId },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
};
