import { useCallback, useEffect, useReducer } from 'react';

import { SESSION_KEY } from '@utils/axios';
import * as firebase from '@utils/firebase';
import { logEvent } from '@utils/firebase/log';

import { User } from '@partners/models/User';
import { findUserBy } from '@partners/services';

import type { User as FirebaseUser } from 'firebase/auth';

export type AuthUser = {
  uid: string;
  name: string;
  admin: boolean;
  email: string | null;
  partner?: {
    id: string;
    name: string;
    email: string;
  };
  accessToken?: string;
};

export type AuthHook = {
  signOut: () => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
} & State;

export type State = {
  submiting: boolean;
  loading: boolean;
  hasError?: boolean;
  authUser: Partial<AuthUser> | undefined;
};

const INITIAL_STATE = {
  submiting: false,
  loading: true,
  hasError: false,
  authUser: undefined,
};

enum ACTION {
  LOGIN_INIT = 'LOGIN_INIT',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  FAILURE = 'LOGIN_FAILURE',
  AUTH_CHANGE_SUCCESS = 'AUTH_CHANGE_SUCCESS',
  LOGOUT = 'LOGOUT',
  TOOGLE_LOADING = 'TOOGLE_LOADING',
}

type Reducer = (state: State, action: Action) => State;

type Action =
  | {
      type: ACTION.LOGIN_SUCCESS;
      payload: { user: FirebaseUser; partner: User['partner']; accessToken: User['accessToken'] };
    }
  | { type: ACTION.FAILURE }
  | { type: ACTION.LOGIN_INIT }
  | {
      type: ACTION.AUTH_CHANGE_SUCCESS;
      payload: { partner: User['partner']; accessToken: User['accessToken']; admin: User['admin'] };
    }
  | { type: ACTION.LOGOUT }
  | { type: ACTION.TOOGLE_LOADING; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION.LOGIN_INIT:
      return {
        ...state,
        submiting: true,
        loading: true,
      };

    case ACTION.LOGIN_SUCCESS: {
      const { accessToken, partner, user } = action.payload;

      logEvent('login', {
        email: user.email,
        uid: user.uid,
        name: user.displayName,
        partnerUid: partner?.id || user.uid,
        partnerName: partner?.name || 'Admin',
        partnerEmail: partner?.email || user.email,
      });

      return {
        ...state,
        authUser: partner
          ? {
              uid: partner.id,
              name: partner.name,
              email: partner.email,
              admin: false,
              accessToken,
            }
          : { uid: user.uid, admin: true, email: user.email, name: 'Admin', accessToken },
        submiting: false,
        loading: false,
      };
    }

    case ACTION.FAILURE:
      return {
        ...state,
        authUser: undefined,
        submiting: false,
        loading: false,
        hasError: true,
      };

    case ACTION.TOOGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ACTION.AUTH_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        authUser: {
          name: action.payload?.partner?.name || state.authUser?.name,
          email: action.payload?.partner?.email || state.authUser?.email,
          uid: action.payload?.partner?.id || state.authUser?.uid,
          accessToken: action.payload?.accessToken || state.authUser?.accessToken,
          admin: action.payload?.admin || state.authUser?.admin,
        },
      };

    case ACTION.LOGOUT:
      return {
        ...state,
        authUser: undefined,
        loading: false,
      };

    default:
      return state;
  }
};

export const useFirebaseAuth = () => {
  const [state, dispatch] = useReducer<Reducer>(reducer, INITIAL_STATE);

  const signOut = useCallback(async () => {
    await firebase.auth.signOut();

    dispatch({ type: ACTION.LOGOUT });
  }, []);

  const authStateChanged = useCallback(
    async (user: FirebaseUser | null) => {
      try {
        if (!user) {
          dispatch({ type: ACTION.TOOGLE_LOADING, payload: false });
          return;
        }

        dispatch({ type: ACTION.TOOGLE_LOADING, payload: true });

        let partner = {} as User['partner'];
        let accessToken = '';
        let admin = false;

        if (!state.authUser) {
          accessToken = await user.getIdToken();

          const {
            admin: foundAdmin,
            email,
            id,
            partner: foundPartner,
          } = await findUserBy({
            authenticationId: user.uid,
            accessToken,
          });

          partner = foundPartner || { id, email, name: 'Admin' };
          admin = foundAdmin;
        }

        dispatch({ type: ACTION.AUTH_CHANGE_SUCCESS, payload: { partner, accessToken, admin } });
      } catch (error) {
        await signOut();

        dispatch({ type: ACTION.LOGOUT });
      }
    },
    [signOut, state.authUser]
  );

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      dispatch({ type: ACTION.LOGIN_INIT });

      const { user } = await firebase.signIn(email, password);
      const accessToken = await user.getIdToken();

      const { partner } = await findUserBy({ authenticationId: user.uid, accessToken });

      dispatch({ type: ACTION.LOGIN_SUCCESS, payload: { user, partner, accessToken } });
    } catch (error) {
      dispatch({ type: ACTION.FAILURE });
    }
  }, []);

  useEffect(() => {
    if (state.authUser?.accessToken) {
      const { accessToken } = state.authUser;
      localStorage.setItem(SESSION_KEY, JSON.stringify(accessToken));
    }
  }, [state.authUser]);

  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged(authStateChanged);

    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    signIn,
    signOut,
    ...state,
  } as AuthHook;
};
