import { rest } from 'msw';

import { getNodeEnv } from '../../utils/env.ts';
import * as fixtures from '../fixtures';

const { apiHost } = getNodeEnv();

// mocked calls used on the application
const handlers = [
  //  getImplementations
  rest.get(`${apiHost}/api/implementation`, (_, res, ctx) =>
    res(ctx.status(200), ctx.json(fixtures.get_implementation_200))
  ),
];

export default handlers;
