import { ReactNode, createContext, useContext } from 'react';

import type { AuthHook } from '@hooks/useAuth';
import { useFirebaseAuth } from '@hooks/useAuth';

const authUserContext = createContext<AuthHook>({} as AuthHook);

type Props = {
  children: ReactNode;
  value?: AuthHook;
};

export function AuthUserProvider({ children, value }: Props) {
  const auth = useFirebaseAuth();

  return <authUserContext.Provider value={value || auth}>{children}</authUserContext.Provider>;
}

export const useAuth = () => useContext(authUserContext);
