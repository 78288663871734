import { useCallback, useEffect } from 'react';

import Head from 'next/head';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { UnitedGlobalStyle, vndaTheme } from '@olist/united';

import { AuthUserProvider } from '@contexts/AuthUserContext';

import i18n from '@i18n/setup';

import { GlobalStyle } from '@styles/globals';
import theme from '@styles/theme';
import { getNodeEnv } from '@utils/env';

import type { AppProps } from 'next/app';

const env = getNodeEnv();

// eslint-disable-next-line global-require
if (env.isMockedApi) require('@mocks');

const customTheme = {
  ...vndaTheme,
  breakpoints: theme.breakpoints,
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const cleanupLocalData = useCallback(() => {
    const tags = ['__NEXT_DATA__'];

    tags.forEach((tag) => {
      if (!tag) return null;

      const node = document.getElementById(tag);
      if (node) node.remove();

      return null;
    });
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    cleanupLocalData();
  }, [cleanupLocalData]);

  return (
    <>
      <Head>
        <link rel="icon" type="image/svg+xml" href="favicon.svg" />
        <title>Network | Vnda</title>
        <Script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-T37SD2P');
            `,
          }}
        />
      </Head>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-T37SD2P');
          `,
        }}
      />
      <AuthUserProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={customTheme}>
            <UnitedGlobalStyle />
            <GlobalStyle />
            <Component {...pageProps} />
            <NextNProgress />
          </ThemeProvider>
        </I18nextProvider>
      </AuthUserProvider>
    </>
  );
};

export default MyApp;
