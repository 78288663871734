/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosResponse } from 'axios';

import { signOut } from '@utils/firebase';

export const SESSION_KEY = 'session';

export const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(
  async (request) => {
    const accessToken = (localStorage.getItem(SESSION_KEY) || '').replace(/"/g, '');

    if (accessToken) {
      const headers = { ...request.headers, Authorization: `Bearer ${accessToken}` };
      request.headers = headers;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status;
      const UNAUTHORIZED = 401;

      if (statusCode === UNAUTHORIZED) {
        signOut();
      }
    }
    return Promise.reject(error);
  }
);
