import ImplementationsMockHandles from './implementation.mock';
import PlatformMockHandlers from './platform.mock';
import SegmentsMockHandlers from './segment.mock';
import StatusMockHandlers from './status.mock';
import UserMockHandlers from './user.mock';

export const handlers = [
  ...ImplementationsMockHandles,
  ...PlatformMockHandlers,
  ...SegmentsMockHandlers,
  ...StatusMockHandlers,
  ...UserMockHandlers,
];
