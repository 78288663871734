import { api } from '@utils/axios';

import * as Model from '@partners/models/Opportunity';

export const getOpportunities = async (
  params?: Model.GetOpportunitiesRequest
): Promise<Model.GetOpportunitiesResponse> => {
  const { agency, limit, nextPageHash } = params || ({} as Model.GetOpportunitiesRequest);

  const response = await api.get(`/opportunity`, {
    params: { limit, agency: agency === 'Admin' ? '' : agency, nextPageHash },
  });

  return response.data;
};

export const createOpportunity = async (
  opportunity: Model.CreateOpportunityRequest
): Promise<Model.CreateOpportunitiesResponse> => {
  const response = await api.post(`/opportunity`, opportunity);

  return response.data;
};
