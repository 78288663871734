import { rest } from 'msw';

import { getNodeEnv } from '../../utils/env.ts';
import * as fixtures from '../fixtures';

const { apiHost } = getNodeEnv();

// mocked calls used on the application
const handlers = [
  //  getSegments
  rest.get(`${apiHost}/api/segment`, (_, res, ctx) =>
    res(ctx.status(200), ctx.json(fixtures.getSegments))
  ),
];

export default handlers;
